exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-us-jsx": () => import("./../../../src/pages/about-us.jsx" /* webpackChunkName: "component---src-pages-about-us-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-industries-components-header-jsx": () => import("./../../../src/pages/industries/components/Header.jsx" /* webpackChunkName: "component---src-pages-industries-components-header-jsx" */),
  "component---src-pages-industries-jsx": () => import("./../../../src/pages/industries.jsx" /* webpackChunkName: "component---src-pages-industries-jsx" */),
  "component---src-pages-insights-jsx": () => import("./../../../src/pages/insights.jsx" /* webpackChunkName: "component---src-pages-insights-jsx" */),
  "component---src-pages-markdown-remark-frontmatter-slug-jsx": () => import("./../../../src/pages/{markdownRemark.frontmatter__slug}.jsx" /* webpackChunkName: "component---src-pages-markdown-remark-frontmatter-slug-jsx" */),
  "component---src-pages-services-components-customer-support-jsx": () => import("./../../../src/pages/services/components/CustomerSupport.jsx" /* webpackChunkName: "component---src-pages-services-components-customer-support-jsx" */),
  "component---src-pages-services-components-header-jsx": () => import("./../../../src/pages/services/components/Header.jsx" /* webpackChunkName: "component---src-pages-services-components-header-jsx" */),
  "component---src-pages-services-components-service-jsx": () => import("./../../../src/pages/services/components/Service.jsx" /* webpackChunkName: "component---src-pages-services-components-service-jsx" */),
  "component---src-pages-services-jsx": () => import("./../../../src/pages/services.jsx" /* webpackChunkName: "component---src-pages-services-jsx" */),
  "component---src-templates-industry-jsx": () => import("./../../../src/templates/Industry.jsx" /* webpackChunkName: "component---src-templates-industry-jsx" */),
  "component---src-templates-insight-jsx": () => import("./../../../src/templates/Insight.jsx" /* webpackChunkName: "component---src-templates-insight-jsx" */),
  "component---src-templates-service-jsx": () => import("./../../../src/templates/Service.jsx" /* webpackChunkName: "component---src-templates-service-jsx" */)
}

